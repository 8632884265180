const { parseEnvVar } = require('./utils')

// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  org: {
    contact: {
      displayName: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_DISPLAYNAME, 'VuexyCRM'),
      address: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_ADDRESS, '1 Main Street'),
      city: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_CITY, 'New York City'),
      postalCode: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_POSTALCODE, '10001'),
      province: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_PROVINCE, 'New York'),
      country: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_COUNTRY, 'USA'),
      phoneNumber: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_PHONENUMBER, '1 (888) 888-8888'),
      email: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_EMAIL, 'admin@vuexycrm.com'),
      url: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ORG_CONTACT_URL, 'https://vuexycrm.com'),
    },
  },
  app: {
    appName: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_APPNAME, 'Business CRM'), // Will update name in navigation menu (Branding),
    // eslint-disable-next-line global-require
    appLogoImage: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_APPLOGOIMAGE, require('@/assets/images/logo/logo.svg')), // Will update logo in navigation menu (Branding)
  },
  layout: {
    isRTL: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_LAYOUT_ISRTL, false),
    skin: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_LAYOUT_SKIN, 'light'), // light, dark, bordered, semi-dark
    routerTransition: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_LAYOUT_ROUTERTRANSITION, 'zoom-fade'), // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_LAYOUT_TYPE, 'horizontal'), // vertical, horizontal
    contentWidth: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_LAYOUT_CONTENTWIDTH, 'boxed'), // full, boxed
    menu: {
      hidden: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_LAYOUT_MENU_HIDDEN, false),
      isCollapsed: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_LAYOUT_MENU_ISCOLLAPSED, false),
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_NAVBAR_TYPE, 'sticky'), // static , sticky , floating, hidden
      backgroundColor: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_NAVBAR_BACKGROUNDCOLOR, ''), // BG color options [primary, success, etc]
    },
    footer: {
      type: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_FOOTER_TYPE, 'static'), // static, sticky, hidden
    },
    customizer: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_CUSTOMIZER, false),
    enableScrollToTop: parseEnvVar(process.env.VUEXYCRM_FRONTEND_THEMECONFIG_ENABLESCROLLTOTOP, true),
  },
}
