import { $api } from '@apiConfig'

export default {
  // Endpoints
  loginEndpoint: `${$api.app.auth}/login`,
  registerEndpoint: `${$api.app.auth}/register`,
  refreshEndpoint: `${$api.app.auth}refresh-token`,
  logoutEndpoint: `${$api.app.auth}/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
