/**
 * parse environment variable (optionally against its type), if none, then set to the defaultValue provided,
 * @param varname
 * @param defaultValue
 * @param type
 * @returns {*}
 */
const parseEnvVar = (value, defaultValue) => {
  try {
    const type = typeof defaultValue
    if (value) {
      switch (type) {
        case 'string':
          return value
        case 'boolean':
          return value === 'true'
        case 'number':
          return Number(value)
        default:
          return value
      }
    } else {
      return defaultValue
    }
  } catch (err) {
    return value
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  parseEnvVar,
}
