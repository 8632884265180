// eslint-disable-next-line import/prefer-default-export
export const $app = {
  axios: {
    options: {
      timeout: 10000,
    },
  },
  toast: {
    options: {
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    },
  },
}
