const { parseEnvVar } = require('./utils')

// eslint-disable-next-line import/prefer-default-export
export const $api = {
  token: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_TOKEN, 'token'),
  app: {
    auth: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_AUTH, 'http://endpoint/admin'),
    quote: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_QUOTE, 'http://endpoint/quote'),
    user: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_USER, 'http://endpoint/user'),
    todo: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_TODO, 'http://endpoint/todo'),
    event: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_EVENT, 'http://endpoint/event'),
    client: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_CLIENT, 'http://endpoint/client'),
    order: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_ORDER, 'http://endpoint/order'),
    object: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_OBJECT, 'http://endpoint/object'),
    product: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_APP_PRODUCT, 'http://endpoint/product'),
  },
  storage: {
    objectBaseUrl: parseEnvVar(process.env.VUEXYCRM_FRONTEND_APICONFIG_STORAGE_OBJECTBASEURL, 'http://endpoint'),
  },
}
