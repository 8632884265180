import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSignaturePad from 'vue-signature-pad'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSignaturePad)
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formatDate', value => (new Date(value)).toLocaleString())

Vue.filter('formatKey', value => value.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
  .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`))

// eslint-disable-next-line no-nested-ternary
Vue.filter('formatValue', value => (typeof value === 'boolean' ? value === true ? 'Yes' : 'No' : value))

Vue.filter('getOrderListTotal', orderList => {
  let total = 0
  orderList.forEach(order => {
    try {
      total += order.paymentDetails.total
    } catch (e) {
      total += 0
    }
  })
  return total
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
