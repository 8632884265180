import Vue from 'vue'
import { $app } from '@appConfig'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  ...$app.axios.options,
})

Vue.prototype.$http = axiosIns

export default axiosIns
