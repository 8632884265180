import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = []
try {
  if (userData) {
    if (userData.roles && userData.roles.length > 0) {
      userData.roles.forEach(role => {
        const { code } = role

        if (code.includes('admin')) {
          existingAbility.push({
            action: 'manage',
            subject: 'all',
          })
        }
      })
    }
  }
// eslint-disable-next-line no-empty
} catch (error) {
}

export default new Ability(existingAbility.length > 0 ? existingAbility : initialAbility)
